.mantine-Modal-modal {
    padding: 0;
    padding-right: .55rem;
    border-radius: 12px;
}

.mantine-Modal-header {
    background-color: var(--modal-color) !important;
    border-radius: 12px 12px 0 0;
    padding: 1rem;
    color: white;
    font-size: 1.5em;
    text-align: center;
}

.mantine-Modal-close {
    color: white;
    opacity: .8;
    position: absolute;
    right: 1rem;
}

.mantine-Modal-close:hover {
    background-color: unset;
    opacity: 1;
}

.mantine-Modal-close:focus {
    outline: 0 !important;
}


.mantine-Modal-title {
    margin-left: auto;
    margin-right: auto;
}

.mantine-Modal-body h1,
.mantine-Modal-body h2,
.mantine-Modal-body h3,
.mantine-Modal-body h4,
.mantine-Modal-body h5,
.mantine-Modal-body h6,
.mantine-Modal-body p,
.mantine-Modal-body .btn,
.mantine-Modal-body section {
    color: #333;
    font-weight: 300;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    text-align: center;
}