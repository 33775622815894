.detail-indicator .big-number {
    /* font-size: 3.5em; */
    font-size: clamp(1.5em, 3.5vw, 3.5em);
}

/* font size on mobile */
@media (max-width: 767px) {
    .detail-indicator .big-number {
        font-size: 4em;
    }
}

.cta-lens {
    position: relative;
}

.cta-lens-icon {
    position: absolute;
    top: 2px;
    right: 1.5rem;
    font-size: .8em;
    opacity: .5;
}