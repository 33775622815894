.regional-indicator-number-container {
    max-width: 11rem;
}

.regional-indicator-name {
    min-height: 3.4rem;
    opacity: .6;
    transition: .2s;
    font-size: clamp(1.1em, 1.2vw, 1.3em);

}

.regional-indicator-name:hover {
    opacity: 1;
}


.regional-indicator-number {
    /* font-size: 3em; */
    font-size: clamp(1.1em, 2.8vw, 3.5em);
    font-weight: 400;
}