.mantine-Slider-bar {
    background-color: var(--main-color);
}

.filters-panel {
    text-align: left;
    background-color: white;
    padding: 1.3rem;
    border-radius: 15px;
}


.mantine-Accordion-control:hover {
    background-color: unset;
}

.mantine-Accordion-panel {
    overflow-x: auto !important;
    overflow-y: auto !important;
}

.projects-counter {
    font-weight: 500;
}

.mantine-Accordion-item {
    border-bottom: 0px;
}

.mantine-Checkbox-input:checked {
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.beneficiario-multiselect span,
.beneficiario-multiselect input::placeholder {
    font-size: .8em !important;
}

.clear-filters {
    position: absolute;
    top: -15px;
    /*  center */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: .8em;
    color: var(--main-color);
    background-color: #EFEFEF;
    border-radius: 18px;
    opacity: .7;
}


.clear-filters .btn {
    padding-top: .1rem !important;
    padding-bottom: .1rem !important;
    opacity: .9;
}

.clear-filters .btn:hover {
    opacity: 1;
}