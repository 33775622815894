#schedaprogetto {
    background-color: #F7F7F7;
}

#schedaprogetto p {
    font-weight: 300;
    font-size: 1.2em;
    color: #555;
}

#schedaprogetto .settore-badge,
#schedaprogetto .sistema-badge {
    background-color: #f0f0f0;
    color: #333;
    border-radius: 20px;
    padding: .7rem !important;
    padding-left: .8rem !important;
    padding-right: .8rem !important;
    z-index: 999 !important;
    transition: .4s;
    font-size: 1em;
}

#schedaprogetto .small-badge {
    padding: .4rem !important;
    padding-left: .8rem !important;
    padding-right: .8rem !important;
}

#schedaprogetto .scrollable-systems {
    /* overflow next line */

    /* overflow: auto !important; */
    /* hide scrollbars */
    /* -ms-overflow-style: none;  IE and Edge */
    /* scrollbar-width: none;  Firefox */
    /* hide scrollbars on chrome */
    /* overflow: -moz-scrollbars-none; */
}

#schedaprogetto .scrollable-systems::-webkit-scrollbar {
    display: none;
}

#go-back-list {
    position: absolute;
    left: -40px;
    top: 10px;
    opacity: .4;
    transition: .4s;
}

#go-back-list:hover {
    opacity: 1;
    cursor: pointer;
}

.scrollable-beneficiari {
    max-height: 18.5rem !important;
    overflow-y: auto;
}

#points-card {
    transition: .3s;
}