body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

i {
  /* font-family: sans-serif !important; */
}


:root {
  --main-color: #CB1D15;
}


.bg-main {
  background-color: var(--main-color) !important;
}

.opacity-7 {
  opacity: .7;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.card {
  border-radius: 10px !important;
}



.RadialLabel {
  display: none;
}

.am5exporting-list {
  background-color: white !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
}

.am5exporting-type-separator,
.am5exporting-item {
  border-bottom: 0px !important;
}

.am5exporting-menu .am5exporting-type-separator a:hover {
  background-color: #fff !important;
  color: rgb(173, 173, 173) !important;
  font-size: .9em !important;

}

.am5exporting-item a:hover {
  background-color: var(--main-color) !important;
  color: white !important;
}

.am5exporting-item a {
  padding: 3px 15px !important;
  font-size: 1em;
}

.am5exporting-icon:focus,
.am5exporting-icon:hover,
.am5exporting-menu-open .am5exporting-icon {
  background-color: unset !important;
}

.apexcharts-menu-open {
  border: 0 !important;
  padding: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 3px;
  padding-bottom: .7rem !important;
  padding-top: .7rem !important;
}

.apexcharts-menu-open::before {
  content: "Export" !important;
  position: relative;
  top: -2px;
  left: -53px;
  color: rgb(173, 173, 173) !important;

  font-size: .9em !important;
  text-align: start !important;
}

.apexcharts-menu-item.exportCSV {
  display: none !important;
}
/* .apexcharts-menu-item.exportSVG {
  display: none !important;
} */

.am5exporting-type-format a::before {
  content: "Download ";
}

.am5exporting-label-alt {
  display: none !important;
}

.apexcharts-menu-item {
  padding: 6px 13px 6px 10px !important;
  font-size: 1em;
}

.apexcharts-menu-item:hover {
  background-color: var(--main-color) !important;
  color: white !important;
}

.cursor-pointer {
  cursor: pointer !important;
}