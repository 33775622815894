#repository {
    background-color: #F7F7F7;
    max-width: 110rem;
}

.repository .project {
    background-color: white;
    height: 100%;
    border-radius: 15px;
    padding: 1.3rem;
}

.show-overflow-x {
    overflow-x: scroll;
}

/* ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
} */