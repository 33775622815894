/* .apexcharts-bar-area {
    z-index: 1 !important;
}
*/
.apexcharts-bar-area {
    cursor: pointer;
}


.apexcharts-tooltip {
    padding: 0rem !important;
    border-radius: 8px !important;
    border: 0px !important;
    /* box-shadow: none !important; */
}

.ap-tooltip {
    text-align: left !important;
    background-color: white !important;
    font-weight: 400 !important;
    border-radius: 8px !important;
    border: 0px !important;
    box-shadow: none !important;
}

.ap-tooltip-title {
    background-color: var(--main-color) !important;
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    padding-top: .5rem !important;
    padding-bottom: .1rem !important;
    color: white;
    font-weight: 400 !important;
    margin: auto;
}

.ap-tooltip-content {
    text-align: center;
    font-weight: 400 !important;
    font-size: 1.2em;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
    /* color: var(--accent-color) !important */
    color: #333 !important
}


.apexcharts-data-labels:hover text,
.apexcharts-backgroundBar:hover text,
.apexcharts-bar-area:hover text,
g:hover text {
    color: 'red' !important;
    fill: 'red' !important;
}