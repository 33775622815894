.apexcharts-tooltip {
    padding: 0rem !important;
    border-radius: 8px !important;
    border: 0px !important;
    /* box-shadow: none !important; */
    display: none;
}

.ap-tooltip {
    text-align: left !important;
    background-color: white !important;
    font-weight: 400 !important;
    border-radius: 8px !important;
    border: 0px !important;
    box-shadow: none !important;
}

.apexcharts-tooltip-title {
    background-color: var(--main-color) !important;
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    padding-top: .5rem !important;
    padding-bottom: .1rem !important;
    color: white;
    font-weight: 400 !important;
    margin: 0 !important;
    font-size: 1.1em !important;
    text-align: center !important;
}

.apexcharts-tooltip-series-group {
    text-align: center;
    font-weight: 100 !important;
    font-size: 1.1em;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
    /* color: var(--accent-color) !important */
    color: #333 !important;
}

.apexcharts-tooltip-text {
    font-weight: 100 !important;
    font-size: 1.1em !important;
    text-align: center !important;
}