#documenti .copertina-tilted {
    border-radius: 10px;
    transform: perspective(1000px) rotateY(20deg);
    transition: .4s;
}

#documenti .copertina-tilted:hover {
    transform: perspective(1000px) rotateY(0deg);
}

/* mobile breakpoint */
@media (max-width: 767px) {
    #documenti .copertina-tilted {
        height: 220px;
    }
}