.legend-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
}

.legend {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: red;
}

.text {
    font-size: .85em;
}