.transition-4s {
    transition: .4s;
}

.cursor-default {
    cursor: default;
}

.project-card .title {
    font-weight: 400;
}

.project-card .systems {
    overflow: hidden;
}

.project-card .settore-badge,
.project-card .sistema-badge {
    background-color: #f1f1f1;
    color: #333;
    border-radius: 20px;
    padding: .7rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    transition: .4s;
    font-size: 1em;
}

.project-card .project-link {
    text-decoration: none;
    transition: .4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background-color: #f1f1f1;
    color: #333 !important;
    opacity: .8;
}

.project-card .project-link:hover {
    opacity: 1;
}

.project-card .sistema-badge {
    position: relative;
    width: auto;
    display: inline-flex;
    align-items: center;
    transition: .4s;
}

.my-container {
    height: 2.5rem;
    position: relative;
    width: auto;
    display: inline-flex;
    align-items: center;
}

.add {
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    background: #2794A5;
    border-radius: 20px;
    height: 100%;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.my-text {
    white-space: nowrap;
    position: relative;
    z-index: 1;
    height: 100%;
    width: 0;
    color: #fff;
    overflow: hidden;
    transition: 0.3s all ease;
    background: #2794A5;
    height: 100%;
    display: flex;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: 20px;
    padding-left: 20px;
    cursor: pointer;
}

.my-container:hover .my-text {
    width: 100%;
    padding-right: 20px;
}