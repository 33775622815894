.project-search {
    background-color: #EFEFEF;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-radius: 0 0 10px 10px;
}

.sco {
    position: relative;
    width: 100%;
}

.project-search .search-icon {
    position: absolute;
    right: 0;

    /* align vertically with position absolute */
    top: 23%;
    right: 30px;

}

.mantine-MultiSelect-searchInput {
    font-size: 1.3em;
    opacity: .7;
    padding-left: .5rem;
}

.project-search .mantine-Input-input {
    border: 0;
    padding: .8rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 10px;
}

.mantine-MultiSelect-value {
    padding-top: .8rem;
    padding-bottom: .8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2em;
    border-radius: 10px;
}

.mantine-Radio-radio:checked {
    background: #cb1d15;
    border-color: #cb1d15;
}