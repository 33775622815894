.footer-container {
    background-color: #30373d;
    color: #fff;
}



.invert-colors {
    filter: invert(1)
}

.footer-logo {
    width: 30rem;
    max-width: 100%;
}

.footer-container a {
    color: #65DDE0;
    padding-top: .3rem;
    padding-bottom: .3rem;
}

.footer-container a:hover {
    background-color: #65DDE0;
    color: black;
}

.footer-container .footer-logo:hover {
    background-color: transparent !important;
}

.footer-container ul {
    list-style: none;
    padding-left: 0;
}

.footer-container ul li:before {
    padding-right: 15px;
    display: inline-block;
    font-family: 'Times New Roman', Times, serif;
    content: "⏵";
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1em;
}

.footer-container .fab {
    background-color: #65DDE0;
    color: #30373d;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 9990px;
    opacity: .9;
    transition: .1s;
}

.footer-container .social-icons a:hover {
    background-color: #30373d;
}

.footer-container .social-icons a:hover .fab {
    opacity: 1;
    /* background-color: #30373d; */
    /* color: #65DDE0; */
    /* border: 1px solid #65DDE0; */
}

#return-to-top {
    transition: .25s !important;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: black;
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    text-decoration: none;
    border-radius: 35px;
    display: none;
    transition: all 0.3s linear;
    z-index: 1000;
}

.underline-only-hover {
    text-decoration: none !important;
    opacity: .9 !important;
}

.underline-only-hover:hover {
    text-decoration: underline !important;
    opacity: 1 !important;
}

.footer-left h6 {
    font-size: 0.9em;
}