.language-header {
    background-color: #eee;
}

.logos-link {
    text-decoration: none;
    color: #333;
}

.logos-link:hover {
    text-decoration: none;
    color: #333;
}

.language-header .er-link {
    color: #333;
    font-weight: bold;
    text-decoration: none;
}

.language-header .er-link:hover {
    text-decoration: underline;
}

.mantine-Select-input {
    background-color: transparent !important;
    text-align: right;
    border: 0px;
    font-size: 1.1em;
    font-weight: bold;
}


.nav-link {
    color: #fff !important;
    opacity: .8;
    font-size: 20px !important;
    margin: auto;
    margin-right: 4rem;
    transition: .2s !important;
}

.text-menu {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

/* media query for mobile */
@media (max-width: 768px) {
    .nav-link {
        margin-right: 1rem;
    }

    .text-menu {
        font-size: 18px !important;
    }

    .nav-item {
        transform: scale(0.9);
    }

}

.nav-link.active,
.nav-link:hover {
    opacity: 1 !important;
}

.navbar {
    margin-top: .6rem;
    /* max-height: 3.3rem; */
    font-weight: 600;
}

/* on desktop query */
@media (min-width: 768px) {
    .navbar {
        max-height: 3.3rem;
    }
}

.navbar .menu-icon {
    background-color: white;
    border-radius: 42%;
    padding: .2rem;
}

.logos-header .bg-fas {
    background-color: white;
    border-radius: 45%;
    /* height: 50px; */
    /* width: 50px; */
    /* font-size: smaller; */
    color: var(--main-color);
    margin-left: .5rem;
    /* padding: .4rem; */
}

.search-input {
    border: 1.5px solid var(--main-color) !important;
    border-radius: 0px !important;
}

.btn-search {
    background-color: var(--main-color) !important;
    color: white !important;
    border-radius: 0px !important;
    margin-left: .5rem;
}

/* media query for mobiles */
@media (max-width: 768px) {
    .nav-link .text-menu {
        display: none;
    }

    .nav-link.active .text-menu {
        display: unset;
    }

    ul {}
}