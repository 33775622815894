#cruscotto {
    background-color: #F7F7F7;
    max-width: 110rem;
}

.quick-filters {
    text-align: left;
    background-color: white;
    padding: 1.3rem;
    border-radius: 15px;
}

.mantine-RadioGroup-label {
    color: #6c757d !important;
    font-weight: 400;
}

.cruscotto-content {
    background-color: white;
    height: 100%;
    border-radius: 15px;
    padding: 1.3rem;

}

.mantine-Tabs-tab[data-active] {
    border-color: #CB1D15 !important;
}

.download-btn {
    text-align: left;
    border-radius: 15px !important;
}