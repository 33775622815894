.collaborators-table {
    max-height: 34rem;
    overflow-y: auto;
}

.collaboratori-title {
    position: sticky;
    top: 0;
    background-color: white;

    padding-bottom: 1rem !important;
}



/* only on desktop */
@media (min-width: 992px) {
    .partecipante-name {
        max-width: 50%
    }
}