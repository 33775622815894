#panoramica {
    background-color: white;
    overflow-x: hidden;
}

.big-button {
    color: #444;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    max-width: 14rem;
    /* width: 14rem; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    opacity: .9;
    transform: translateY(0);
    transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.discover-more a {
    text-decoration: none !important;
}

.big-button:hover {
    cursor: pointer;
    opacity: 1;
    transform: translateY(-5px);

}

.invert {
    filter: invert(1);
}

.saturation-0 {
    mix-blend-mode: multiply;
    /* filter: saturate(0) brightness(.7) contrast(1); */
}