.big-indicators-container {
    border: 1.5px solid var(--main-color);
    padding: 2rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    border-radius: 12px;
    transition: .2s;
}

/* mobile  */
@media (max-width: 768px) {
    .big-indicators-container {
        padding: 1rem;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

.dashed.big-indicators-container {
    /* border: 1.5px dashed white !important; */
    border: 0;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23CB1D15FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
}

.big-indicators-title {
    background-color: white;
    margin-top: -15px !important;
    margin-left: 40px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
}