.controlbar,
.yasr_external_ref_btn,
.yasqe_share {
    display: none !important;
}

.yasgui .switch input[type="checkbox"] {
    margin-left: .5rem !important;
}

.yasgui .tableFilter,
.yasgui .tableSizer {
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 0.4rem;
}

.yasgui .tabsList .tab a:hover {
    border-bottom-color: #CB1D1570 !important;
}

.yasgui .tabsList .tab.active a {
    border-bottom-color: var(--main-color) !important;
}