#timeline-content {
    margin-top: 50px;
    text-align: center;
}

#previous,
#previous:before {
    color: #cbcbcb;
    transition: .3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

#previous:hover,
#previous:hover::before {
    color: black;
}

/* Timeline */
.timeline {
    border-left: 4px dashed #ddd;
    /* margin: 50px auto; */
    margin-left: 23%;
    letter-spacing: 0.5px;
    padding-left: 50px;
    padding-right: 50px;
    list-style: none;
    text-align: left;
    max-width: 70%;
}

.big-number {
    font-size: 4em;
    margin: 0;
    font-weight: 400;
}

.big-number-info {
    font-size: 2em;
    font-weight: 200;
}

.small-number {
    font-size: 2.5em;
    margin: 0;
    font-weight: 400;
}

.small-number-info {
    font-size: 1.5em;
    font-weight: 200;
}


.timeline .event {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    padding-bottom: 25px;
    margin-bottom: 50px;
    position: relative;
}

.timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}


.timeline .event:before {
    left: -200px;
    color: black;

    content: attr(data-date);
    text-align: left;
    max-width: 120px;
    top: 25%;
}

.timeline .event:after {
    box-shadow: 0 0 0 3px #ddd;
    left: -61px;
    background: #fff;
    border-radius: 50%;
    height: 19px;
    width: 19px;
    content: "";
    top: 30%;
}


/* mobile mediaquery */
@media screen and (max-width: 768px) {
    .timeline {

        border-left: 4px dashed #ddd;
        margin-left: 50px;
        /* margin-left: 40%; */
        letter-spacing: 0.5px;
        padding-left: 50px;
        padding-right: 10px;
        list-style: none;
        text-align: left;
        max-width: 100vw;
    }

    .timeline .event:before {
        font-size: .8em;
        transform: rotate(-90deg);
        left: -150px !important;
        text-align: center !important;
    }

    .event.current:before {
        top: 30% !important;
        left: -120px !important;
    }

    .event.strategy-start:before {
        top: 23% !important;  
    }

    .event.previous:before {
        top: 30% !important;
        left: -120px !important;
    }

    .big-number {
        font-size: 13vw;
    }

    .big-number-info {
        font-size: 4.5vw;
    }

    .small-number-info {
        font-size: 4.5vw;
    }

    .description-text {
        font-size: 4.3vw;
    }
}

.event.current:before {
    top: 28%;
    left: -180px;
}

.event.strategy-start:before {
    top: 12%;
    text-align: right;
}

.event.previous:before {
    top: 25%;
    left: -180px;
}