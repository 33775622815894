.underline-gradient {
    position: relative;
}

.underline-gradient::before {
    content: "";
    position: absolute;
    top: 120%;
    width: 100%;
    left: 0;
    height: 3px;
    border-radius: 1px;
    background: linear-gradient(90deg, var(--main-color) 15.6%, #fff 80%);
}

.copertina {
    width: 170px;
    object-fit: contain;
    transition: .2s;
}

.copertina:hover {
    cursor: pointer;
    transform: translateY(-5px);
}
